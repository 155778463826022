@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Inter-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Inter-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("./fonts/Inter-Medium.woff2") format("woff2");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("./fonts/Inter-MediumItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/Inter-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/Inter-BoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("./fonts/Inter-ExtraBold.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Inter-Black.woff2") format("woff2");
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");
